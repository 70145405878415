<template lang="pug">
	article#posts
		router-link(:to="{name: 'home'}").logo
			img(:src="require('@/assets/images/logo/gtcompany-color.png')", alt="GT. Company - Negócios Inteligentes")
		h1 {{ pagina.titulo }}
		p.sub {{ pagina.subtitulo }}

		// ul(v-if="!isImprensa").categorias
		// 	li(v-for="categoria in categorias", @click="changeCategory(categoria.slug)", :class="{ ativo: categoriaAtiva === categoria.slug }").categoria {{ categoria.nome }}

		ul.materias
			li(v-for="conteudo, index in destaques").materia
				CardPost(v-bind="conteudo", :key="`destaque-${conteudo.slug}`")
		Listagem(:categoria="categoriaAtiva")
		LerMais(:action="isImprensa ? sectionLerMais.blog : sectionLerMais.imprensa", titulo="Você pode querer ler")
</template>

<script>
import qs from 'qs'
import Listagem from '@/sections/Posts/Listagem/Listagem'

import LerMais from '@/components/LerMais/LerMais.vue'
import CardPost from '@/components/CardPost/CardPost'

export default {
	name: 'view-posts',
	components: {
		CardPost,
		LerMais,
		Listagem,
	},
	data() {
		return {
			destaques: [],
			categorias: [],
			categoriaAtiva: '',
			sectionLerMais: {
				imprensa: {
					titulo: 'Imprensa',
					subtitulo: 'A GT Company é destaque em grandes publicações. Confira as últimas notícias.',
					botao: 'Clique aqui',
					rota: 'imprensa'
				},
				blog: {
					titulo: 'Blog',
					subtitulo: 'Fique por dentro dos negócios e novidades da GT Company.',
					botao: 'Leia mais',
					rota: 'blog'
				},
			},
		}
	},
	computed: {
		isImprensa() {
			return 'imprensa' === this.$route.name
		},
		pagina() {
			if (this.isImprensa)
				return {
					titulo: 'Imprensa',
					subtitulo: 'A GT Company é destaque em grandes publicações. Confira as últimas notícias.'
				}
			return {
				titulo: 'Blog',
				subtitulo: 'Fique por dentro dos negócios e novidades da GT Company.'
			}
		},
	},
	created() {
		this.loadDestaques()
		if (!this.isImprensa)
			this.loadCategorias()
	},
	methods: {
		loadDestaques(categoria = '') {
			let params = {
				limit: 3,
				destaques: true,
				categoria: this.isImprensa ? 'imprensa' : categoria,
			}
			this.$axios
				.get(`conteudo?${qs.stringify(params)}`)
				.then(response => {
					this.destaques = response.data.registros.data
				})
		},
		loadCategorias() {
			this.$axios
				.get(`categoria`)
				.then(response => {
					this.categorias = response.data.registros
				})
		},
		changeCategory(categoria) {
			this.categoriaAtiva = categoria
			this.loadDestaques(categoria)
		}
	},
}
</script>

<style lang="stylus" scoped src="./Posts.styl"></style>
